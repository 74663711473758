@font-face {
  font-family: Artnoova;
  src: url("../fonts/Artnoova-Thin.4c2c1e3d.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Artnoova;
  src: url("../fonts/Artnoova-Light.c4ddfbec.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Artnoova;
  src: url("../fonts/Artnoova-Regular.e55169f6.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Artnoova;
  src: url("../fonts/Artnoova-Medium.e0236d9b.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-Light.22d8cb3e.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-Regular.b1c82d88.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-Medium.fa8b1a3b.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

*, :before, :after {
  box-sizing: inherit;
}

:root {
  --light-fg: #196666;
  --light-bg: #fff4e0;
  --dark-fg: #f9e9c8;
  --dark-bg: #125454;
  --backdrop: #000a0acc;
  --fg: var(--light-fg);
  --bg: var(--light-bg);
  --theme-toggle-timing: 2s;
  --content-width: 66em;
  --gutter: 3em;
  --spacing: 3em;
  background: var(--bg);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--fg);
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  font: 300 22px / 1.32 Metropolis, sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    --fg: var(--dark-fg);
    --bg: var(--dark-bg);
  }
}

:root[data-theme="light"] {
  --fg: var(--light-fg);
  --bg: var(--light-bg);
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
}

:root[data-theme="dark"] {
  --fg: var(--dark-fg);
  --bg: var(--dark-bg);
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

:root.loaded {
  -webkit-transition: var(--theme-toggle-timing) background-color, .4s color;
  -moz-transition: var(--theme-toggle-timing) background-color, .4s color;
  transition: var(--theme-toggle-timing) background-color, .4s color;
}

@media (width <= 1280px) {
  :root {
    --gutter: 2em;
  }
}

@media (width <= 540px) {
  :root {
    --gutter: 1em;
  }
}

body {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

h1 {
  font-family: Artnoova, sans-serif;
  font-size: 2.2em;
  font-weight: 200;
}

h2 {
  font-family: Artnoova, sans-serif;
  font-weight: 300;
}

a {
  color: inherit;
  text-underline-offset: .15em;
  text-decoration-thickness: from-font;
}

a:hover {
  -webkit-text-decoration-style: dashed;
  -moz-text-decoration-style: dashed;
  text-decoration-style: dashed;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

main {
  margin: var(--spacing) auto;
  max-width: calc(var(--content-width)  + 2 * var(--gutter));
  padding-inline: var(--gutter);
  width: 100%;
}

.page--home, .page--404 {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.page--404 h1 {
  margin: 0;
  font-size: 5em;
  font-weight: 200;
}

body > footer {
  --bg: var(--dark-bg);
  --fg: var(--dark-fg);
  background: var(--bg);
  color: var(--fg);
  margin-block: var(--spacing) 0;
  padding-block: var(--spacing);
}

body > footer > div {
  margin: var(--spacing) auto;
  max-width: calc(var(--content-width)  + 2 * var(--gutter));
  padding-inline: var(--gutter);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

body > footer a {
  font-size: smaller;
  -webkit-text-decoration-line: none;
  -moz-text-decoration-line: none;
  text-decoration-line: none;
}

body > footer a:hover {
  -webkit-text-decoration-line: underline;
  -moz-text-decoration-line: underline;
  text-decoration-line: underline;
}

@media (width <= 640px) {
  body > footer {
    padding-block: var(--gutter);
  }

  body > footer > div {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  body > footer small {
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -webkit-order: 3;
    order: 3;
    margin-top: .4em;
  }

  body > footer a {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -webkit-order: 2;
    order: 2;
    margin-top: 1em;
  }
}

.fslightbox-container.fslightbox-container {
  background: var(--backdrop);
}

.fslightbox-container.fslightbox-container .fslightbox-slide-number-container {
  color: var(--dark-fg);
}

.fslightbox-container.fslightbox-container .fslightbox-toolbar {
  background: var(--dark-bg);
}

.fslightbox-container.fslightbox-container .fslightbox-slide-btn {
  background: var(--dark-bg);
  border-radius: 9999em;
}

.fslightbox-container.fslightbox-container .fslightbox-svg-path {
  fill: var(--dark-fg);
}

body > header {
  --bg: var(--dark-bg);
  --fg: var(--dark-fg);
  background: var(--bg);
  color: var(--fg);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1em;
  padding-bottom: 1em;
}

body > header > div {
  margin: var(--spacing) auto;
  max-width: calc(var(--content-width)  + 2 * var(--gutter));
  padding-inline: var(--gutter);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

body > header h1 {
  text-transform: uppercase;
  align-items: center;
  margin: 0;
  font-size: 50px;
  line-height: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

body > header h1 .logomark {
  cursor: pointer;
  border-radius: 9999em;
  margin-left: -.16em;
  margin-right: -.16em;
}

body > header h1 .logomark[hidden] {
  display: none;
}

body > header h1 a {
  margin-left: .4em;
  text-decoration: none;
}

@media (width <= 720px) {
  body > header h1 {
    font-size: 38px;
  }

  body > header h1 .logomark {
    width: 60px !important;
    height: 60px !important;
  }
}

body > header .themeToggle {
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

body > header .themeToggle .hex {
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  will-change: transform;
}

body > header .themeToggle svg {
  -webkit-transition: var(--theme-toggle-timing) fill;
  -moz-transition: var(--theme-toggle-timing) fill;
  transition: var(--theme-toggle-timing) fill;
  fill: var(--bg);
  display: block;
}

body > header .themeToggle:hover .hex {
  -webkit-transition: -webkit-transform .3s, -moz-transform .3s, -ms-transform .3s, transform .3s;
  -moz-transition: -webkit-transform .3s, -moz-transform .3s, -ms-transform .3s, transform .3s;
  transition: -webkit-transform .3s, -moz-transform .3s, -ms-transform .3s, transform .3s;
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

body > header .themeToggle .rays {
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform .4s .4s, -moz-transform .4s .4s, -ms-transform .4s .4s, transform .4s .4s;
  -moz-transition: -webkit-transform .4s .4s, -moz-transform .4s .4s, -ms-transform .4s .4s, transform .4s .4s;
  transition: -webkit-transform .4s .4s, -moz-transform .4s .4s, -ms-transform .4s .4s, transform .4s .4s;
}

body > header .themeToggle .inner {
  -webkit-transition: -webkit-transform .4s .4s, -moz-transform .4s .4s, -ms-transform .4s .4s, transform .4s .4s;
  -moz-transition: -webkit-transform .4s .4s, -moz-transform .4s .4s, -ms-transform .4s .4s, transform .4s .4s;
  transition: -webkit-transform .4s .4s, -moz-transform .4s .4s, -ms-transform .4s .4s, transform .4s .4s;
}

[data-theme="light"] :is(body > header .themeToggle) .rays {
  -webkit-transition-delay: .2s;
  -moz-transition-delay: .2s;
  transition-delay: .2s;
  -webkit-transform: scale(.75);
  -moz-transform: scale(.75);
  -ms-transform: scale(.75);
  transform: scale(.75);
}

[data-theme="dark"] :is(body > header .themeToggle) .inner {
  -webkit-transition-delay: .2s;
  -moz-transition-delay: .2s;
  transition-delay: .2s;
  -webkit-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
}

.page--instructions figure {
  margin: var(--spacing) 0;
}

.page--instructions figure > img, .page--instructions figure > [x-data="swiper"] {
  border-radius: 1.4em;
}

.page--instructions figure .has-lighbox {
  cursor: pointer;
}

@media (width >= 961px) {
  .page--instructions figure {
    align-items: flex-start;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: flex;
  }

  .page--instructions figure > * {
    width: calc(50% - var(--gutter) / 2);
  }

  .page--instructions figure > :first-child {
    margin-right: var(--gutter);
  }
}

@media (width <= 960px) {
  .page--instructions h1 {
    margin: 0;
    font-size: 1.8em;
  }
}

.page--leafy-warbler {
  margin: var(--spacing) auto;
  max-width: calc(var(--content-width)  + 2 * var(--gutter));
  padding-inline: var(--gutter);
  column-gap: var(--gutter);
  grid-auto-flow: dense;
  grid-template-columns: repeat(2, calc(50% - var(--gutter) / 2));
  grid-template-rows: 0fr 1fr;
  width: 100%;
  display: grid;
}

.page--leafy-warbler header {
  grid-column: 2;
}

.page--leafy-warbler header h1 {
  font-size: 2.2em;
  font-weight: 300;
}

.page--leafy-warbler header .price {
  font-family: Artnoova, sans-serif;
  font-size: 2em;
  font-weight: 200;
}

.page--leafy-warbler header .price small {
  margin-right: .2em;
  font-size: .56em;
  font-weight: 300;
}

.page--leafy-warbler header .buy {
  background: var(--fg);
  box-shadow: 0 0 0 0 var(--bg), 0 0 0 0 var(--fg);
  color: var(--bg);
  vertical-align: .25em;
  border-radius: 999em;
  margin-left: 1em;
  padding: .2em 1em;
  font-family: Artnoova, sans-serif;
  font-size: 1.1em;
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: -webkit-box-shadow .15s, box-shadow .15s;
  -moz-transition: -webkit-box-shadow .15s, box-shadow .15s;
  transition: -webkit-box-shadow .15s, box-shadow .15s;
  display: inline-block;
}

.page--leafy-warbler header .buy:hover {
  box-shadow: 0 0 0 3px var(--bg), 0 0 0 5px var(--fg);
}

.page--leafy-warbler header .notice {
  font-size: .8em;
}

.page--leafy-warbler header .notice:before {
  content: "!";
  text-align: center;
  border: thin solid;
  border-radius: 9999em;
  width: 1.3em;
  height: 1.3em;
  margin-right: .2em;
  font-family: Artnoova, sans-serif;
  font-weight: 400;
  line-height: 1.18;
  display: inline-block;
}

.page--leafy-warbler header .notice strong {
  font-weight: 500;
}

.page--leafy-warbler header .notice span {
  display: inline-block;
}

.page--leafy-warbler .gallery {
  aspect-ratio: 1;
  background: var(--light-fg);
  border-radius: 1.4em;
  grid-row: 1 / span 2;
  margin: 0;
  overflow: hidden;
}

.page--leafy-warbler .gallery .swiper-slide.video {
  aspect-ratio: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  align-items: center;
  padding: 1.4em;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.page--leafy-warbler .gallery video {
  border-radius: 1.4em;
  max-width: 100%;
}

.page--leafy-warbler ul li {
  margin-top: .4em;
  margin-bottom: .4em;
}

.page--leafy-warbler > video {
  border: .2em solid var(--light-fg);
  margin-top: var(--spacing);
  border-radius: 1.4em;
  grid-column: 1 / span 2;
  justify-self: center;
  width: 960px;
  max-width: 100%;
}

.page--leafy-warbler .details {
  align-self: center;
}

.page--leafy-warbler dl {
  grid-template-columns: max-content 1fr;
  display: grid;
}

.page--leafy-warbler dl dt, .page--leafy-warbler dl dd {
  margin-left: 1em;
}

.page--leafy-warbler svg.distance {
  vertical-align: text-bottom;
}

.page--leafy-warbler .leaf-models {
  margin: var(--spacing) 0 0;
  border-radius: 1.4em;
  grid-column: 2;
}

.page--leafy-warbler .diagram {
  margin: var(--spacing) 0 0;
  border-radius: 1.4em;
}

@media (width <= 960px) {
  .page--leafy-warbler {
    display: block;
  }

  .page--leafy-warbler header {
    text-align: center;
    margin-bottom: 2em;
  }
}

@media (width <= 380px) {
  .page--leafy-warbler header .buy {
    margin: 1em 1.4em;
  }
}

[x-data="swiper"] {
  width: 100%;
  position: relative;
  overflow: hidden;
}

[x-data="swiper"] .navigation button {
  background: var(--dark-bg);
  color: var(--dark-fg);
  border: none;
  border-radius: 9999em;
  width: 3em;
  height: 3em;
  padding: 0;
  top: auto;
  bottom: .8em;
}

[x-data="swiper"] .navigation button:after {
  font-size: 1.8em;
  -webkit-transition: -webkit-transform .15s, -moz-transform .15s, -ms-transform .15s, transform .15s;
  -moz-transition: -webkit-transform .15s, -moz-transform .15s, -ms-transform .15s, transform .15s;
  transition: -webkit-transform .15s, -moz-transform .15s, -ms-transform .15s, transform .15s;
}

[x-data="swiper"] .navigation button.swiper-button-prev {
  padding-right: .3em;
}

[x-data="swiper"] .navigation button.swiper-button-prev:hover:after {
  -webkit-transform: translate(-.15em);
  -moz-transform: translate(-.15em);
  -ms-transform: translate(-.15em);
  transform: translate(-.15em);
}

[x-data="swiper"] .navigation button.swiper-button-next {
  padding-left: .3em;
}

[x-data="swiper"] .navigation button.swiper-button-next:hover:after {
  -webkit-transform: translate(.15em);
  -moz-transform: translate(.15em);
  -ms-transform: translate(.15em);
  transform: translate(.15em);
}
/*# sourceMappingURL=main.3187c3ef.css.map */
